import React from "react";
import { Job } from "../../generated/nest-graphql";
import { DetailViewContainer } from "../Contacts/DetailsViewContainer";
import { SelectablePageableEntityTableRenderer } from "../TableViewsPages/SelectablePageableEntityTableRenderer";
import { Link } from "react-router-dom";
import { formatDateTime } from "../../lib/functions";

export const StatusSnapshot: React.FC<{
  snapshotData?: any;
  job: Job
}> = ({ snapshotData, job }) => {
  return <DetailViewContainer title={"Status Snapshot"} defaultExpanded={true}>
    <SelectablePageableEntityTableRenderer
        title={
        <div className="flex items-center gap-2">
          <Link className="underline" to={`/jobs/${job.id}`}>Job {job.jobNumber}</Link>
          <span className="text-sm">{formatDateTime(job.invoice?.createdAt || job.appointment.startDate)}</span>
        </div>
      }
        currentPage={0}
        columns={[
            { Header: "Service", accessor: "service" },
            { Header: "Thickness", accessor: "thickness" },
            { Header: "Condition", accessor: "condition" },
            { Header: "Status", accessor: "status" },
            { Header: "Replaced", accessor: "replaced" }
        ]}
        numRecords={snapshotData?.length || 0}
        data={snapshotData || []}
        canNextPage={false}
        nextPage={undefined}
        canPrevPage={false}
        prevPage={undefined}
        hiddenSelection
    />
  </DetailViewContainer>

}