import { useMutation, useQuery, useLazyQuery } from "@apollo/client";
import { Helmet } from "react-helmet";
import { Chip } from "@material-ui/core";
import { concat, cond, keys, omit, path, pathOr, pipe, prop, propOr, T } from "ramda";
import * as React from "react";
import {
  Mutation,
  MutationUpdateContactArgs,
  MutationUpdateJobArgs,
  Query,
  QueryGetJobArgs,
  QueryGetJobReschedulesArgs,
  QueryGetJobsArgs,
} from "../../generated/nest-graphql";
import { UPDATE_CONTACT } from "../../graphql/mutations/updateContact";
import { UPDATE_JOB } from "../../graphql/mutations/updateJob";
import { GET_JOB } from "../../graphql/queries/getJob";
import { cleanObject, isPresent, objectDiff, sortActivityByCreatedAt } from "../../lib/functions";
import { ActivityFeedDrawer } from "../ActivityFeed/ActivityFeedDrawer";
import { JobDetailsForm, JobDetailsFormValues } from "../Forms/JobDetailsForm";
import { getSymptoms, jobDetailsSpec } from "../specs/jobDetailsSpec";
import { useShowSuccess } from "../../redux/slices/snackbar";
import { DEFAULT_SERVICE_CATALOGUE_USED, SERVICE_CATALOGUE_USED_SERVICES } from "../../lib/constants";
import { servicesToPossibleEjiServiceSpec } from "../specs/servicesSpec";
import { vehicleSymptomToVehicleSymptomFormValue } from "../Contacts/contactDetailsFormHelpers";
import { exists } from "../../commonFunctions";
import { GET_JOB_RESCHEDULES } from "../../graphql/queries/getJobReschedules";
import { GET_JOBS } from "../../graphql/queries/getJobs";
import { SymptomDiagnosisCategory } from "../../data/diagnosisAndRecommendation";

export const JobDetails: React.FC<{ jobId: string }> = ({ jobId }) => {
  const [getJobReschedules, { data: rescheduleData }] = useLazyQuery<Query, QueryGetJobReschedulesArgs>(
    GET_JOB_RESCHEDULES
  );
  const [getJobs, { data: jobHistoryData }] = useLazyQuery<Query, QueryGetJobsArgs>(GET_JOBS);
  const { data, refetch } = useQuery<Query, QueryGetJobArgs>(GET_JOB, {
    variables: {
      id: jobId,
    },
    onCompleted: ({ getJob }) => {
      getJobReschedules({
        variables: {
          jobNumber: getJob?.jobNumber,
        },
      });
      getJob?.contact?.id &&
        getJobs({
          variables: {
            limit: 50,
            filter: {
              contact: getJob?.contact?.id,
              status: "Closed",
            },
          },
        });
    },
  });

  const [updateJob] = useMutation<Mutation, MutationUpdateJobArgs>(UPDATE_JOB);
  const [updateContact] = useMutation<Mutation, MutationUpdateContactArgs>(UPDATE_CONTACT);

  const showSuccess = useShowSuccess();
  if (!data || !rescheduleData) return null;
  const results = data.getJob;
  const jobNumber = prop("jobNumber", results);
  const contact = results.contact;
  const initialValues: JobDetailsFormValues = {
    type: results.type,
    appointmentId: null,
    status: results.status,
    zendeskTags: (results as any).zendeskTags ?? [],
    jobName: results.jobName,
    taxable: !exists(results?.contact?.taxExemptEin) ?? results.taxable ?? true,
    estimate: pathOr(null, ["estimate", "id"], results),
    market: results.market,
    withdrawReason: results.withdrawReason,
    withdrawExtraInfo: pathOr("", ["withdrawExtraInfo"], results),
    withdrawnAt: prop("withdrawnAt", results),
    vehicleId: results["vehicleId"] || undefined,
    year: pathOr("", ["vehicleInfo", "year"], results),
    vin: pathOr("", ["vehicleInfo", "vin"], results),
    rearPadLife: pathOr("", ["vehicleInfo", "rearPadLife"], results),
    odometer: pathOr("", ["vehicleInfo", "odometer"], results),
    frontRotorsDiscardThicknessInches: pathOr(null, ["vehicleInfo", "frontRotorsDiscardThicknessInches"], results),
    rearRotorsDiscardThicknessInches: pathOr(null, ["vehicleInfo", "rearRotorsDiscardThicknessInches"], results),
    model: pathOr("", ["vehicleInfo", "model"], results),
    make: pathOr("", ["vehicleInfo", "make"], results),
    licensePlate: pathOr("", ["vehicleInfo", "licensePlate"], results),
    licensePlateState: pathOr("", ["vehicleInfo", "licensePlateState"], results),
    frontPadLife: pathOr("", ["vehicleInfo", "frontPadLife"], results),
    extraInfo: pathOr("", ["vehicleInfo", "extraInfo"], results),
    wheelTorque: pathOr(null, ["vehicleInfo", "wheelTorque"], results),
    symptoms: getSymptoms(results) ?? "",
    customerExpectation: pathOr("", ["vehicleInfo", "customerExpectation"], results),
    additionalNotes: pathOr("", ["vehicleInfo", "additionalNotes"], results),
    frontBrakeSymptoms: pathOr([], ["vehicleInfo", "frontBrakeSymptoms"], results),
    rearBrakeSymptoms: pathOr([], ["vehicleInfo", "rearBrakeSymptoms"], results),
    partsOrderNumber: pathOr("", ["partsInfo", "partsOrderNumber"], results),
    partsOrdered: pathOr(false, ["partsInfo", "partsOrdered"], results),
    partsOrderedTimestamp: pathOr(null, ["partsInfo", "partsOrderedTimestamp"], results),
    cancellationTimestamp: pathOr(null, ["partsInfo", "cancellationTimestamp"], results),
    cancellationInitiated: pathOr(false, ["partsInfo", "cancellationInitiated"], results),
    partsNotes: pathOr("", ["partsInfo", "partsNotes"], results),
    partsOrderingIssue: pathOr(null, ["partsInfo", "partsOrderingIssue"], results),
    partsLocation: pathOr(null, ["partsInfo", "partsLocation"], results),
    partsStore: results.partsStore,
    viocStore: results.viocStore,
    technician: pathOr(null, ["appointment", "technician"], results),
    serviceLocation: results.serviceLocation,
    contact: contact,
    email: results.contact.email,
    description: results.description,
    partsLeadTimeInDays: prop("partsLeadTimeInDays", results),
    callForPartsTicketNumber: pathOr(null, ["partsInfo", "callForPartsTicketNumber"], results),
    // @ts-ignore
    items: results?.items?.map((item) => {
      return {
        name: prop("name", item),
        description: prop("description", item),
        amount: prop("amount", item),
        laborCost: prop("laborCost", item),
        partNumber: prop("partNumber", item),
        partsCost: prop("partsCost", item),
        vendorPartsCost: prop("vendorPartsCost", item),
        isInEstimate: prop("isInEstimate", item),
        product: prop("product", item),
        partsStore: prop("partsStore", item),
      };
    }),
    privateNotes: results.privateNotes,
    serviceLocationNotes: results.serviceLocationNotes,
    jobCausedWarrantyCheck: pathOr(null, ["jobCausedWarrantyCheck", "id"], results),
    jobCausedDiagnostic: pathOr(null, ["jobCausedDiagnostic", "id"], results),
    jobViocBrakeRepair: pathOr(null, ["jobViocBrakeRepair", "id"], results),
    jobViocInspection: pathOr(null, ["jobViocInspection", "id"], results),
    warrantyCheckReason: pathOr("", ["warrantyCheckReason"], results),
    warrantyCheckReasonExtraInfo: pathOr("", ["warrantyCheckReasonExtraInfo"], results),
    warrantyCheckTechReason: pathOr("", ["warrantyCheckTechReason"], results),
    warrantyCheckTechReasonExtraInfo: pathOr("", ["warrantyCheckTechReasonExtraInfo"], results),
    serviceCallReason: pathOr("", ["serviceCallReason"], results),
    serviceCallReasonExtraInfo: pathOr("", ["serviceCallReasonExtraInfo"], results),
    serviceCatalogueUsed: pathOr(DEFAULT_SERVICE_CATALOGUE_USED, ["serviceCatalogueUsed"], results),
    services: cond([
      [
        (_val) => results?.serviceCatalogueUsed === SERVICE_CATALOGUE_USED_SERVICES && results?.services?.length > 0,
        () => results?.services,
      ],
      [T, () => []],
    ])(null),
    discounts: results?.priceInfo?.discounts ?? [],
    promoCodes: results?.priceInfo?.promoCodes ?? [],
    priceInfo: results?.priceInfo,
    vehicleSymptoms: results?.vehicleSymptoms?.map?.(vehicleSymptomToVehicleSymptomFormValue) ?? null,
    requestedServices: results?.requestedServices ?? null,
    pipedriveDealId: propOr("", "pipedriveDealId", results),
    symptomDiagnosisCategory: (results?.symptomDiagnosisCategory as SymptomDiagnosisCategory) ?? null,
    locationType: results.locationType,
    earlyNotice: results.earlyNotice,
    activityFeed: results.activityFeed
  };
  const onSubmit = async (values: JobDetailsFormValues) => {
    const initialValuesWithPossibleEJIs = {
      ...initialValues,
      services: servicesToPossibleEjiServiceSpec(initialValues.services),
    };
    const valuesWithPossibleEJIs = { ...values, services: servicesToPossibleEjiServiceSpec(values.services) };
    const diff = objectDiff(valuesWithPossibleEJIs, initialValuesWithPossibleEJIs);
    const updates = pipe(jobDetailsSpec, omit(["pricingConfig"]), cleanObject)(diff as any) as any;

    const appointment = prop("appointment", updates);
    if (appointment) {
      updates.status = "Scheduled";
    }
    const newEmail = prop("email", values);
    const contactEmail = path(["contact", "email"], values);

    const allUpdates = [
      ...(isPresent(newEmail) && newEmail !== contactEmail
        ? [
            await updateContact({
              variables: {
                id: path(["contact", "id"], values),
                updateContactInput: { email: newEmail },
              },
            }),
            await updateJob({
              variables: {
                id: jobId,
                // If you update the contact email make sure you update the copy too so it's in sync
                updateJobInput: { ...updates, contactCopy: { ...updates.contact, email: newEmail } },
              },
            }),
          ]
        : []),
      ...(keys(updates).length
        ? [
            await updateJob({
              variables: {
                id: jobId,
                // @ts-ignore
                updateJobInput: updates,
              },
            }),
          ]
        : []),
    ];
    if (allUpdates.length) {
      await Promise.all(allUpdates);
      showSuccess({ message: "Successfully Updated Job" });
    }
  };

  const activities = sortActivityByCreatedAt(
    concat(pathOr([], ["getJob", "activityFeed"], data), pathOr([], ["getJob", "appointment", "activityFeed"], data))
  );
  return (
    <div className="flex flex-row">
      <Helmet>
        <title>{`${prop("firstName", contact)} ${prop("lastName", contact)} - ${prop("year", initialValues)} ${prop(
          "make",
          initialValues
        )} ${prop("model", initialValues)} - PO ${jobNumber} - NuBrakes`}</title>
      </Helmet>
      <div className={"flex-1 mr-6"}>
        {path(["getJob", "mHelpId"], data) && (
          <div className="my-2 flex flex-row justify-end">
            <Chip label={"Imported from MHelp"} color={"primary"} />
          </div>
        )}
        <JobDetailsForm
          initialValues={initialValues}
          onSubmit={onSubmit}
          jobId={jobId}
          jobNumber={jobNumber}
          appointment={data.getJob.appointment}
          hasReschedules={rescheduleData?.getJobReschedules?.length > 0}
          reschedules={rescheduleData?.getJobReschedules}
          isRepeatCustomer={jobHistoryData?.getJobs?.edges?.length > 1}
          refetchJob={refetch}
        />
      </div>
      <ActivityFeedDrawer activities={activities} />
    </div>
  );
};
