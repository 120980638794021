import gql from "graphql-tag";
import { jobDetailsFields } from "./getJob";

export const vehiclesDetailsFields = `
    id
    contactId
    make
    model
    subModel
    year
    vin
    name
    licensePlate
    licensePlateState
    odometer
    CCA
    odometerHistory{
        mileage
        updatedAt
        updatedBy
        include
    }
    vehicleTechnicalInfo{
        wheelTorque
        frontRotorsDiscardThicknessInches
        rearRotorsDiscardThicknessInches
        hasDrumBrakes
        parkingBrake
    }
    externalServiceHistory{
      type
      source
      odometer
      displayDate
      text
      displayText
      updatedAt
    }
    notes
    inactive
    createdAt
    updatedAt
`;

export const vehicleMileageFields = `
    currentMilage
    lastServiceDate
    milesPerYear
    mileageOnLastService
    mileageSinceLastService
    repairServicesDetails {
        inCount
        milesUntilRepair
        repairDate
        service
    }
    snapshotData {
        service
        condition
        thickness
        replaced
        service
        servicesIds
        status
    }
    lastJob {
        ${jobDetailsFields}
    }
`

export const GET_VEHICLES = gql`
    query getVehicles($contactId: ID!) {
        getVehicles(contactId: $contactId) {
            ${vehiclesDetailsFields}
        }
    }
    `;

export const GET_VEHICLE = gql`
    query getVehicle($id: ID!) {
        getVehicle(id: $id) {
            ${vehiclesDetailsFields}
        }
    }
`;

export const GET_VEHICLE_MILEAGE = gql`
    query getVehicleMileage($vehicleId: ID!, $odometerHistoryInput: [OdometerHistoryInput]) {
        getVehicleMileage(vehicleId: $vehicleId, odometerHistoryInput: $odometerHistoryInput) {
            ${vehicleMileageFields}
        }
    }
`;