import gql from "graphql-tag";

export const appointmentDetailsFields = `
      id
      allDay
      isFixed
      endDate
      startDate
      subject
      timeZone
      timeWindow {
        startTimeWindow
        endTimeWindow
      }
      technician {
        id
        firstName
        lastName
        market
        isActive
        homeAddress
        is1099
        email
      }
      job {
        id
        status
        jobName
        jobNumber
        serviceLocation
        type
        market
        zendeskTags
        partsStore {
          name
        }
        partsInfo {
          partsOrdered
        }
        services {
          inEstimate
          technicianPay
          items {
            vendorUnitCost
            units
          }
        }
        priceInfo {
          finalSubTotal
        }
      }
      overrideOutsideServiceZone
  `;
export const GET_APPOINTMENTS = gql`
  query getAppointments($startRange: Date!, $endRange: Date!, $filter: JSON) {
    getAppointments(
      startRange: $startRange
      endRange: $endRange
      filter: $filter
    ) {
      ${appointmentDetailsFields}
    }
  }
`;
