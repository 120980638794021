import React from "react";
import { useQuery } from "@apollo/client";
import { Query, Market } from "../../generated/nest-graphql";
import { GET_MARKETS } from "../../graphql/queries/getMarkets";
import { find, path, prop, propEq, pluck } from "ramda";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";

export const SchedulingMarketSelect: React.FC<{
  value: string;
  setValue?: (val: any) => void;
  postOnChange?: (val: any) => void;
}> = ({ value, setValue, postOnChange }) => {
  const { data } = useQuery<Query>(GET_MARKETS);
  if (!data) {
    return null;
  }

  // @ts-ignore
  const markets: Market[] = prop("getMarketsV2", data);

  return (
    <div className="flex justify-end">
      <div>
        <FormControl variant="filled">
          <InputLabel>Market</InputLabel>
          <Select
            fullWidth
            label={"Market"}
            onChange={(event) => {
              // @ts-ignore
              const value = path<string>(["target", "value"], event);
              // @ts-ignore
              const market: Market =
                value === "All"
                  ? {
                      name: "All",
                      timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
                    }
                  : find(propEq("name", value))(markets);
              setValue({
                name: market.name,
                timeZone: market.timeZone,
              });
              postOnChange && postOnChange(value);
            }}
            value={value}
          >
            <MenuItem value="All">
              <em>All</em>
            </MenuItem>
            {pluck("name", markets).map((val: any, i) => (
              <MenuItem key={i} value={val}>
                {val}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>
    </div>
  );
};
