import { Form, Formik } from "formik";
import { defaultTo, startsWith } from "ramda";
import * as React from "react";
import { useRef, useState } from "react";
import Modal from "react-modal";
import * as Yup from "yup";
import {
  Activity,
  Appointment,
  Contact,
  EjiDiscount,
  EjiPriceInfo,
  EjiService,
  Mutation,
  MutationNoticeLowMarginJobArgs,
  PartsStore,
  PossibleEjiService,
  PromoCode,
  Reschedule,
  Technician,
  ViocStore,
} from "../../generated/nest-graphql";
import { ItemsTableType } from "../Items/ItemsTable";
import { VehicleInfoValues } from "../VehicleInfo/VehicleInfoFormSection";
import { AppointmentFormValues } from "./AppointmentForm";
import { JobDetailsInternalForm } from "./JobDetailsInternalForm";
import { flow } from "fp-ts/lib/function";
import { servicesSchema } from "../../yupSchemas";
import { VehicleSymptomFormValues } from "./ContactDetailsForm";
import { Button } from "../Buttons/Button";
import { NuModal } from "../NuModal";
import { servicesToPossibleEjiServiceSpec } from "../specs/servicesSpec";
import { SERVICE_CATALOGUE_USED_SERVICES } from "../../lib/constants";
import { useToggle } from "../../hooks/useToggle";
import { exists } from "../../commonFunctions";
import { CancelButton } from "../Buttons/CancelButton";
import { SymptomDiagnosisCategory } from "../../data/diagnosisAndRecommendation";
import { NOTICE_LOW_MARGIN_JOB } from "../../graphql/mutations/noticeLowMarginJob";
import { useMutation } from "@apollo/client";

const regexpMarginAlert =
  /Margin Alert Acknowledged. Customer Price: \$([0-9.]+), Vendor Parts Cost: \$([0-9.]+), Technician Pay: \$([0-9.]+)/;

export const jobDetailsFormValidationSchema = Yup.object().shape({
  status: Yup.string().required("Required"),
  contact: Yup.object().required("Required"),
  // serviceLocation: Yup.string().required("Required"),
  market: Yup.string().required("Required"),
  type: Yup.string().required("Required"),
  //Maybe this validations should be added to vehicle object
  /*year: Yup.string().when('type', {
    is: value => value === "Vioc Inspection",
    then: Yup.string().nullable(),
    otherwise: Yup.string().required("Required")
  }),
  make: Yup.string().when('type', {
    is: value => value === "Vioc Inspection",
    then: Yup.string().nullable(),
    otherwise: Yup.string().required("Required")
  }),
  model: Yup.string().when('type', {
    is: value => value === "Vioc Inspection",
    then: Yup.string().nullable(),
    otherwise: Yup.string().required("Required")
  }),*/
  // jobName: Yup.string().required("Required"),
  withdrawReason: Yup.string().when("status", {
    is: startsWith("Withdraw"),
    then: Yup.string().required("Must Provide a Withdraw Reason"),
    otherwise: Yup.string().nullable(),
  }),
  withdrawExtraInfo: Yup.string().when("withdrawReason", {
    is: flow(defaultTo(""), startsWith("Other")),
    then: Yup.string().required("Must Provide Additional Details"),
    otherwise: Yup.string().nullable(),
  }),
  jobCausedWarrantyCheck: Yup.string().when("type", {
    is: (value) => value === "Warranty Check",
    then: Yup.string()
      .length(24)
      .required(
        "Must Provide a JobID of the job that caused the warranty, the job ID is the url of the job in the format ops.nubrakes.com/jobs/<jobid>"
      ),
    otherwise: Yup.string().nullable(),
  }),
  jobCausedDiagnostic: Yup.string().when("type", {
    is: (value) => value === "Pre-Job Diagnostic",
    then: Yup.string()
      .length(24)
      .required(
        "Must Provide a JobID of the job that caused the diagnostic, the job ID is the url of the job in the format ops.nubrakes.com/jobs/<jobid>"
      ),
    otherwise: Yup.string().nullable(),
  }),
  warrantyCheckReason: Yup.string().when("type", {
    is: flow(defaultTo(""), startsWith("Warranty")),
    then: Yup.string().required("Must Provide a Warranty Reason"),
    otherwise: Yup.string().nullable(),
  }),
  warrantyCheckReasonExtraInfo: Yup.string().when(["type", "warrantyCheckReason"], {
    is: (type, reason) =>
      flow(defaultTo(""), startsWith("Warranty"))(type) && flow(defaultTo(""), startsWith("Other"))(reason),
    then: Yup.string().required("Must Provide Additional Details"),
    otherwise: Yup.string().nullable(),
  }),
  warrantyCheckTechReason: Yup.string().nullable(),
  warrantyCheckTechReasonExtraInfo: Yup.string().when(["type", "warrantyCheckTechReason"], {
    is: (type, reason) =>
      flow(defaultTo(""), startsWith("Warranty"))(type) && flow(defaultTo(""), startsWith("Other"))(reason),
    then: Yup.string().required("Must Provide Additional Details"),
    otherwise: Yup.string().nullable(),
  }),
  serviceCallReason: Yup.string().when("type", {
    is: (type) => type.startsWith("Service Call"),
    then: Yup.string().required("Must Provide Service Call Reason"),
    otherwise: Yup.string().nullable(),
  }),
  serviceCallReasonExtraInfo: Yup.string().when(["type", "serviceCallReason"], {
    is: (type, reason) =>
      flow(defaultTo(""), startsWith("Service Call"))(type) && flow(defaultTo(""), startsWith("Other"))(reason),
    then: Yup.string().required("Must Provide Additional Details"),
    otherwise: Yup.string().nullable(),
  }),
  services: servicesSchema,
  //vin: Yup.string().length(17, "VIN must be 17 digits").nullable(),
});

export type JobDetailsFormValues = VehicleInfoValues & {
  id?: string;
  zendeskTags?: string[];
  technician?: Technician;
  contact: Contact;
  taxable: boolean;
  email?: string;
  estimate?: string;
  serviceLocation?: string;
  status: string;
  withdrawReason?: string;
  withdrawExtraInfo?: string;
  withdrawnAt?: Date;
  type: string;
  market: string;
  appointmentId: string;
  jobName: string;
  privateNotes?: string;
  serviceLocationNotes?: string;
  locationType?: string;
  description?: string;
  items: ItemsTableType[];
  partsOrderNumber?: string;
  partsNotes?: string;
  partsOrderingIssue?: string;
  partsLocation?: string;
  partsStore?: PartsStore;
  viocStore?: ViocStore;
  partsOrdered?: boolean;
  partsOrderedTimestamp?: Date;
  cancellationInitiated?: boolean;
  cancellationTimestamp?: Date;
  appointmentInfo?: AppointmentFormValues;
  jobCausedWarrantyCheck?: string;
  jobViocBrakeRepair?: string;
  jobViocInspection?: string;
  warrantyCheckReason?: string;
  warrantyCheckReasonExtraInfo?: string;
  warrantyCheckTechReason?: string;
  warrantyCheckTechReasonExtraInfo?: string;
  serviceCallReason?: string;
  serviceCallReasonExtraInfo?: string;
  jobCausedDiagnostic?: string;
  rescheduleReason?: string;
  rescheduleFiledBy?: string;
  serviceCatalogueUsed?: string;
  services?: PossibleEjiService[] | EjiService[];
  discounts?: EjiDiscount[];
  promoCodes?: PromoCode[];
  priceInfo?: EjiPriceInfo;
  partsLeadTimeInDays?: number;
  callForPartsTicketNumber?: string;
  vehicleSymptoms?: VehicleSymptomFormValues[];
  requestedServices?: string[];
  pipedriveDealId?: string;
  symptomDiagnosisCategory?: SymptomDiagnosisCategory;
  earlyNotice?: boolean;
  vehicleId?: string;
  activityFeed?: Activity[];
};

export const JobDetailsForm: React.FC<{
  reschedules?: Reschedule[];
  initialValues: JobDetailsFormValues;
  onSubmit: (values: any, helpers: any) => void;
  jobId?: string;
  appointment: Appointment;
  showAddAppointment?: boolean;
  jobNumber?: string;
  hasReschedules?: boolean;
  isRepeatCustomer?: boolean;
  refetchJob?: () => void;
}> = ({
  initialValues,
  onSubmit,
  jobId,
  jobNumber,
  appointment,
  isRepeatCustomer,
  showAddAppointment = false,
  hasReschedules = false,
  reschedules,
  refetchJob,
}) => {
  const [errorModalIsOpen, , toggleErrorModal] = useToggle();
  const [errorMessage, setErrorMessage] = useState("");
  const [missingDescriptionModalIsOpen, , toggleMissingDescriptionModal] = useToggle();
  const [lowGrossMarginModalIsOpen, , toggleLowGrossMarginModal] = useToggle();
  const [isLoadingLowGrosMarginActivivy, setIsLoadingLowGrosMarginActivivy] = useState(false);
  const [missingLeadTimeModalIsOpen, , toggleMissingLeadTimeModal] = useToggle();
  const [firsSubmit, setFirstSubmit] = useState(false);
  Modal.setAppElement("#root");
  const [isExceptionVehicle, setIsExceptionVehicle] = useState(false);
  const [itemModalIsOpen, setItemModalIsOpen] = useState(false);
  const toggleItemModal = () => {
    setItemModalIsOpen(!itemModalIsOpen);
  };
  const [noticeLowMarginJob] = useMutation<Mutation, MutationNoticeLowMarginJobArgs>(NOTICE_LOW_MARGIN_JOB);
  const [marketMismatchWarningData, setMarketMismatchWarningData] = useState<{
    jobMarket: string;
    technicianMarket: string;
  } | null>(null);
  const confirmedMarketMismatch = useRef(false);

  const wrappedOnSubmit = async (values: JobDetailsFormValues, formikHelpers) => {
    if (exists(values.services)) {
      // Custom validation needs to be done here (instead of using Formik and Yup)
      // since there is no nice way to communicate these error conditions to the
      // user prior to clicking the save button.

      if (isExceptionVehicle && !exists(values.partsLeadTimeInDays)) {
        toggleMissingLeadTimeModal();
        return;
      }

      const valuesWithPossibleEJIs = { ...values, services: servicesToPossibleEjiServiceSpec(values.services) };
      const isServiceCatalogueUsed = valuesWithPossibleEJIs.serviceCatalogueUsed === SERVICE_CATALOGUE_USED_SERVICES;
      const isNoServiceIncludedInEstimate =
        valuesWithPossibleEJIs.services.filter((service) => service.inEstimate).length === 0;
      // const isCustomerPriceZero = currency(valuesWithPossibleEJIs.priceInfo?.amountDue ?? "0.00").intValue === 0;
      if (isServiceCatalogueUsed && isNoServiceIncludedInEstimate) {
        setErrorMessage("At least one service must be included in the estimate to generate a quote.");
        toggleErrorModal();
        return;
      }

      if (isServiceCatalogueUsed && !valuesWithPossibleEJIs?.description) {
        toggleMissingDescriptionModal();
        return;
      }
    }
    if (
      values?.market &&
      appointment?.technician?.market &&
      values?.market !== appointment?.technician?.market &&
      !confirmedMarketMismatch.current
    ) {
      setMarketMismatchWarningData({
        jobMarket: values?.market,
        technicianMarket: appointment?.technician?.market,
      });
      return;
    }

    await onSubmit(values, formikHelpers);
    const jobServices = [];
    let totalTechnicianPay = 0,
      totalVendorPartsCost = 0;
    for (let index = 0; index < values?.services?.length; index++) {
      const service = values?.services[index];
      if (!service?.inEstimate) continue;
      jobServices.push(service);
      totalTechnicianPay += service.technicianPay ? Number(service.technicianPay) : 0;
      totalVendorPartsCost += service.partsCalcs?.vendorCost ? Number(service.partsCalcs.vendorCost) : 0;
    }

    const profit = values.priceInfo.finalSubTotal - totalVendorPartsCost - totalTechnicianPay;
    const grossMargin = values.priceInfo.finalSubTotal ? profit / values.priceInfo.finalSubTotal : 0;
    const grossMarginPercent = grossMargin * 100;
    const beforeAlert = values.activityFeed?.find((value) => `${value?.verb}`.match(regexpMarginAlert));
    const match = `${beforeAlert?.verb}`.match(regexpMarginAlert);
    const withDiferentValues =
      values.priceInfo?.finalSubTotal !== match?.[1] ||
      totalVendorPartsCost?.toFixed(2) !== match?.[2] ||
      totalTechnicianPay?.toFixed(2) !== match?.[3];
    if (
      jobServices.length &&
      grossMarginPercent < 30 &&
      firsSubmit &&
      values.type === "Brake Repair Service" &&
      withDiferentValues
    ) {
      toggleLowGrossMarginModal();
    }
    confirmedMarketMismatch.current = false;
    setFirstSubmit(true);
  };

  return (
    <Formik<JobDetailsFormValues>
      initialValues={initialValues}
      onSubmit={async (values, helpers) => {
        return wrappedOnSubmit(values, helpers);
      }}
      enableReinitialize={true}
      validationSchema={jobDetailsFormValidationSchema}
      validateOnMount
    >
      {({ isSubmitting, submitForm, setValues, isValid, resetForm, values, setFieldValue, touched, validateForm }) => {
        return (
          <Form>
            <JobDetailsInternalForm
              reschedules={reschedules}
              setValues={setValues}
              values={values as any}
              touched={touched}
              isRepeatCustomer={isRepeatCustomer}
              setFieldValue={setFieldValue}
              jobNumber={jobNumber}
              jobId={jobId}
              appointment={appointment}
              showAddAppointment={showAddAppointment}
              toggleItemModal={toggleItemModal}
              submitForm={submitForm}
              resetForm={resetForm}
              initialValues={initialValues}
              isSubmitting={isSubmitting}
              validateForm={validateForm}
              isValid={isValid}
              itemModalIsOpen={itemModalIsOpen}
              hasReschedules={hasReschedules}
              fleet={values.contact?.fleet}
              setIsExceptionVehicle={setIsExceptionVehicle}
            />
            <NuModal isOpen={errorModalIsOpen} title="No Services Included in Estimate">
              <div>
                <p>{errorMessage}</p>
                <Button className="mt-4 float-right" type={"button"} onClick={toggleErrorModal}>
                  OK
                </Button>
              </div>
            </NuModal>
            <NuModal isOpen={missingLeadTimeModalIsOpen} title="No lead time provided">
              <div>
                <p>
                  This is considered an exception vehicle. Make sure to provie a parts lead time in days. Even if you
                  know there is no lead time, you can mark as "None".
                </p>
                <Button className="mt-4 float-right" type={"button"} onClick={toggleMissingLeadTimeModal}>
                  OK
                </Button>
              </div>
            </NuModal>
            <NuModal isOpen={missingDescriptionModalIsOpen} title="No Job Description">
              <div>
                <p>Please provide a Job Description before saving a Job with an estimate.</p>
                <Button className="mt-4 float-right" type={"button"} onClick={toggleMissingDescriptionModal}>
                  OK
                </Button>
              </div>
            </NuModal>
            <NuModal isOpen={lowGrossMarginModalIsOpen} title="Low Margin Alert">
              <div>
                <p>
                  Gross Margin on this job is below 30%. Please review the parts cost and discount totals and take
                  action as needed.
                </p>
                <Button
                  className="mt-4 float-right"
                  type={"button"}
                  disabled={isLoadingLowGrosMarginActivivy}
                  onClick={async () => {
                    setIsLoadingLowGrosMarginActivivy(true);
                    await noticeLowMarginJob({
                      variables: {
                        jobId,
                      },
                    });
                    if (refetchJob) refetchJob();
                    setIsLoadingLowGrosMarginActivivy(false);
                    toggleLowGrossMarginModal();
                  }}
                >
                  Acknowledge
                </Button>
              </div>
            </NuModal>
            <NuModal isOpen={!!marketMismatchWarningData} maxWidth="md" title="Market Mismatch">
              <p>
                Technician market is {marketMismatchWarningData?.technicianMarket} but job market is{" "}
                {marketMismatchWarningData?.jobMarket}. Are you sure you want to proceed?
              </p>
              <div className="flex flex-row mt-8 justify-between">
                <CancelButton type={"button"} onClick={() => setMarketMismatchWarningData(null)}>
                  Cancel
                </CancelButton>
                <Button
                  onClick={() => {
                    confirmedMarketMismatch.current = true;
                    setMarketMismatchWarningData(null);
                    submitForm();
                  }}
                >
                  Yes, proceed anyway
                </Button>
              </div>
            </NuModal>
          </Form>
        );
      }}
    </Formik>
  );
};
