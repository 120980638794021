import gql from "graphql-tag";

export const NOTICE_LOW_MARGIN_JOB = gql`
  mutation noticeLowMarginJob($jobId: ID!) {
    noticeLowMarginJob(jobId: $jobId) {
      id
      actor
      objectId
      objectType
      createdAt
      verb
    }
  }
`;
