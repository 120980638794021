import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import React, { useEffect, useState } from "react";
import {
  Mutation,
  MutationUpsertVehicleArgs,
  Query,
  QueryGetContactArgs,
  QueryGetJobsArgs,
  QueryGetVehicleArgs,
  VehicleType,
  QueryGetVehicleMileageArgs,
} from "../../generated/nest-graphql";
import { GET_VEHICLE, GET_VEHICLE_MILEAGE } from "../../graphql/queries/getVehicles";
import { Box, LinearProgress, Tab, Tabs, Typography } from "@material-ui/core";
import { VehicleDetailsForm, VehicleDetailsFormValues, VehicleForm, vehicleValidationSchema } from "../Forms/VehicleDetailsForm";
import { useHistory } from "react-router-dom";
import { UPSERT_VEHICLE } from "../../graphql/mutations/upsertVehicle";
import { useShowError, useShowSuccess } from "../../redux/slices/snackbar";
import { DetailViewContainer } from "../Contacts/DetailsViewContainer";
import { Space } from "../Space";
import { SelectablePageableEntityTableRenderer } from "../TableViewsPages/SelectablePageableEntityTableRenderer";
import { GET_FIRST_NAME_LAST_NAME } from "../../graphql/queries/getFirstNameLastName";
import { path } from "ramda";
import { Alert, AlertTitle, TabPanel } from "@material-ui/lab";
import { formatDateTime } from "../../lib/functions";
import { GET_JOBS } from "../../graphql/queries/getJobs";
import { ROW_LIMIT } from "../../lib/constants";
import { Link } from "react-router-dom";
import { StatusSnapshot } from "./StatusSnapshot";
import { CellProps } from "react-table";
import { CheckBoxField } from "../FormFields/CheckBoxField";
import { Formik } from "formik";

export const convertVehicleTypeToFormValues = (vehicle: VehicleType): VehicleDetailsFormValues => {
  return {
    id: vehicle?.id,
    contactId: vehicle?.contactId,
    year: vehicle?.year,
    make: vehicle?.make,
    model: vehicle?.model,
    subModel: vehicle?.subModel,
    vin: vehicle?.vin,
    name: vehicle?.name,
    notes: vehicle?.notes,
    licensePlate: vehicle?.licensePlate,
    licensePlateState: vehicle?.licensePlateState,
    odometer: vehicle?.odometer,
    wheelTorque: vehicle?.vehicleTechnicalInfo.wheelTorque,
    frontRotorsDiscardThicknessInches: vehicle?.vehicleTechnicalInfo?.frontRotorsDiscardThicknessInches,
    rearRotorsDiscardThicknessInches: vehicle?.vehicleTechnicalInfo?.rearRotorsDiscardThicknessInches,
    hasDrumBrakes: vehicle?.vehicleTechnicalInfo?.hasDrumBrakes,
    parkingBrake: vehicle?.vehicleTechnicalInfo?.parkingBrake,
    inactive: vehicle?.inactive,
    odometerHistory: (vehicle?.odometerHistory || []).map(item => ({
      mileage: item.mileage,
      include: item.include,
      updatedAt: item.updatedAt,
      updatedBy: item.updatedBy
    })),
    CCA: vehicle.CCA
  };
};

interface OdometerHistory {
  updatedAt: string;
  include: boolean;
  __typename?: "OdometerHistoryType";
  mileage?: string;
  updatedBy?: string;
}

interface ServiceHistory {
  service: string;
  jobId: string;
  jobNumber: string;
  date: string;
  jobType: string;
}

export const VehicleEditView: React.FC<{
  vehicleId: string;
}> = ({ vehicleId }) => {
  const history = useHistory();
  const showSuccess = useShowSuccess();
  const showError = useShowError();
  const [odometerHistory, setOdometerHistory] = useState<OdometerHistory[]>([]);
  const [serviceHistory, setServiceHistory] = useState<ServiceHistory[]>([]);

  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const [upsertVehicle] = useMutation<Mutation, MutationUpsertVehicleArgs>(UPSERT_VEHICLE);
  const { data, loading, error } = useQuery<Query, QueryGetVehicleArgs>(GET_VEHICLE, {
    variables: {
      id: vehicleId,
    },
  });
  const [fetchGetVehicleMilage, {data: vehicleMileage}] = useLazyQuery<Query, QueryGetVehicleMileageArgs>(GET_VEHICLE_MILEAGE)

  useEffect(()=> {
    fetchGetVehicleMilage({
      variables: {
        vehicleId,
      }
    })
  }, [])
  const initialValues: VehicleDetailsFormValues = loading
    ? undefined
    : convertVehicleTypeToFormValues(data?.getVehicle);
  const contactId = initialValues?.contactId || "";

  const externalServiceHistory = data?.getVehicle?.externalServiceHistory || [];
  const job = vehicleMileage?.getVehicleMileage?.lastJob;
  const snapshotData = vehicleMileage?.getVehicleMileage?.snapshotData;
  const nameQuery = useQuery<Query, QueryGetContactArgs>(GET_FIRST_NAME_LAST_NAME, {
    variables: {
      // @ts-ignore
      id: contactId,
    },
    skip: !contactId,
  });

  const {
    data: jobsData,
    loading: loadingJobsData,
    error: errorJobsData
  } = useQuery<Query, QueryGetJobsArgs>(GET_JOBS, {
    variables: {
      limit: ROW_LIMIT,
      filter: {
        contact: contactId,
        vehicleId: vehicleId,
      },
    },
  });

  useEffect(() => {
    const newServiceHistory = loadingJobsData
      ? []
      : jobsData?.getJobs?.edges
        .map((job) => {
          const invoiceServces = job.invoice?.services || [];
          const services = invoiceServces
            .map((service) => ({
              service: service?.name,
              jobId: job.id || "",
              jobNumber: job?.jobNumber || "",
              date: formatDateTime(job?.invoice?.issuedDate),
              jobType: job?.type,
            }));
          return services;
        })
        .flat();
    setServiceHistory(newServiceHistory);
  }, [jobsData?.getJobs?.edges, loadingJobsData]);
  useEffect(() => {
    const newOdometerHistory = loading
      ? []
      : data?.getVehicle?.odometerHistory
        .map((odometerValue) => ({
          ...odometerValue,
          mileage: parseInt(odometerValue.mileage).toString(),
          updatedAt: formatDateTime(odometerValue.updatedAt),
          include: odometerValue.include,
        }))
        .reverse();
    setOdometerHistory(newOdometerHistory);
  }, [data?.getVehicle?.odometerHistory, loading]);

  const onSubmit = async (values: VehicleDetailsFormValues) => {
    try {
      await upsertVehicle({
        variables: {
          vehicleInputData: {
            ...values,
            wheelTorque: String(values.wheelTorque),
            contactId: values.contactId,
            id: vehicleId,
          },
        },
      });
      showSuccess({ message: "Vehicle Successfully Saved" });
    } catch (e) {
      console.log(e);
      showError({ message: "Error Saving Vehicle" });
    }
  };

  const onCancel = () => {
    history.push(`/contacts/${initialValues?.contactId}?tab=vehicles`);
  };


  const isLoading = loading || loadingJobsData || nameQuery?.loading;


  if (isLoading) {
    return null;
  }

  return (
    <Formik<VehicleDetailsFormValues>
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={vehicleValidationSchema}
      enableReinitialize={true}
      validateOnMount>
      {(formController) => {

        return <div className="m-4">
          {!isLoading && !initialValues && (
            <div>
              <Alert severity="error">
                <AlertTitle>Error</AlertTitle>
                Vehicle not found with this ID <strong>{vehicleId}</strong>
              </Alert>
            </div>
          )}
          {isLoading && <LinearProgress color="secondary" />}
          {!isLoading && initialValues && (
            <div>
              <div className={"my-4 text-3xl px-4"}>
                {`${path(["data", "getContact", "firstName"])(nameQuery)} ${path(["data", "getContact", "lastName"])(
                  nameQuery
                )}`}
              </div>
              <VehicleForm initialValues={initialValues} onCancel={onCancel} onSubmit={onSubmit} formController={formController} />
              {job && (
                <div>
                  <StatusSnapshot job={job} snapshotData={snapshotData} />
                </div>
              )}
              <div>
                {!isLoading && (
                  <DetailViewContainer title={"Service History"} defaultExpanded={true}>
                    <Tabs
                      value={value}
                      indicatorColor="primary"
                      textColor="primary"
                      onChange={handleChange}
                      aria-label="Service History tabs"
                    >
                      <Tab label="Nubrakes" />
                      <Tab label="External" />
                    </Tabs>
                    <Box p={3} hidden={value !== 0}>
                      <SelectablePageableEntityTableRenderer
                        title={""}
                        currentPage={0}
                        columns={[
                          { Header: "Service", accessor: "service" },
                          {
                            Header: "Job number",
                            accessor: (values) => values,
                            Cell: (props) => {
                              const { jobId, jobNumber } = props.cell.value;
                              return (
                                <Link className={"text-primary"} to={`/jobs/${jobId}`}>
                                  {jobNumber}
                                </Link>
                              );
                            },
                          },
                          { Header: "Date", accessor: "date" },
                          { Header: "Type", accessor: "jobType" },
                        ]}
                        numRecords={serviceHistory?.length || 0}
                        data={serviceHistory || []}
                        canNextPage={false}
                        nextPage={undefined}
                        canPrevPage={false}
                        prevPage={undefined}
                        hiddenSelection
                      />
                    </Box>
                    <Box p={3} hidden={value !== 1}>
                      <SelectablePageableEntityTableRenderer
                        title={""}
                        currentPage={0}
                        columns={[
                          { Header: "From", accessor: "source" },
                          { Header: "Service", accessor: "displayText" },
                          { Header: "Odometer", accessor: "odometer" },
                          { Header: "Date", accessor: "displayDate" },
                        ]}
                        numRecords={externalServiceHistory?.length || 0}
                        data={externalServiceHistory || []}
                        canNextPage={false}
                        nextPage={undefined}
                        canPrevPage={false}
                        prevPage={undefined}
                        hiddenSelection
                      />
                    </Box>
                  </DetailViewContainer>
                )}
              </div>
              {!isLoading && odometerHistory && (
                <div>
                  <DetailViewContainer title={"Mileage History"} defaultExpanded={true}>
                    <SelectablePageableEntityTableRenderer
                      title={""}
                      currentPage={0}
                      columns={[
                        { Header: "Mileage", accessor: "mileage" },
                        { Header: "UpdatedAt", accessor: "updatedAt" },
                        { Header: "UpdatedBy", accessor: "updatedBy" },
                        {
                          Header: "Include",
                          accessor: "include",
                          Cell: ({ row }: CellProps<any, boolean>) => <CheckBoxField name={`odometerHistory.${odometerHistory.length - row.index - 1}.include`} postOnChange={(newValue) => {
                            const value = JSON.parse(JSON.stringify(formController.values.odometerHistory))
                            value[odometerHistory.length - row.index - 1].include = newValue
                            fetchGetVehicleMilage({
                              variables: {
                                vehicleId,
                                odometerHistoryInput: value
                              }
                            })
                          }} />
                        },
                      ]}
                      numRecords={odometerHistory?.length || 0}
                      data={odometerHistory || []}
                      canNextPage={false}
                      nextPage={undefined}
                      canPrevPage={false}
                      prevPage={undefined}
                      hiddenSelection
                    />
                  </DetailViewContainer>
                </div>
              )}
              <Space />
              {!isLoading && vehicleMileage?.getVehicleMileage && (
                <div>
                  <SelectablePageableEntityTableRenderer
                    title={""}
                    currentPage={0}
                    columns={[
                      { Header: "Estimated Miles driven per year", accessor: "milesPerYear" },
                      { Header: "Estimated current Mileage", accessor: "currentMilage" },
                    ]}
                    numRecords={1}
                    data={[vehicleMileage?.getVehicleMileage]}
                    canNextPage={false}
                    nextPage={undefined}
                    canPrevPage={false}
                    prevPage={undefined}
                    hiddenSelection
                  />
                  <Space />
                </div>
              )}
              {!isLoading && !!vehicleMileage?.getVehicleMileage?.repairServicesDetails?.length && (
                <div>
                  <SelectablePageableEntityTableRenderer
                    title={""}
                    currentPage={0}
                    columns={[
                      { Header: "Service", accessor: "service" },
                      { Header: "Estimated Miles until Repair", accessor: "milesUntilRepair" },
                      { Header: "Estimated Repair Date", accessor: "repairDate" },
                    ]}
                    numRecords={vehicleMileage?.getVehicleMileage?.repairServicesDetails?.length || 0}
                    data={vehicleMileage?.getVehicleMileage?.repairServicesDetails || []}
                    canNextPage={false}
                    nextPage={undefined}
                    canPrevPage={false}
                    prevPage={undefined}
                    hiddenSelection
                  />
                </div>
              )}
              <Space />
            </div>
          )}
        </div>
      }}
    </Formik>
  );
};
